.ConsignerDropOffPage {
  gap: 30px;
  @media (max-width: 768px) {
    margin-bottom: 90px;
  }
}

.requested-page-title{
  width: 100%;
  padding: 20px 16px 0;
  @media (max-width: 768px) {
    padding: 20px 0 0;
  }
}
.requested-inventory-mobile-detail{
  display: flex;
}
.requested-page-searchbox{
  @media (max-width: 768px) {
    margin: 0 !important;
  }
}
.ConsignerDropOffPage .printable {
  display: none;
}

@media print {
  @page {
    size: landscape;
  }
  * {
    visibility: hidden;
  }

  .ConsignerDropOffPage .printable {
    display: block;
  }

  .ConsignerDropOffPage .printable .ant-table-content,
  .ConsignerDropOffPage .printable .ant-table-content * {
    visibility: visible;
  }
}
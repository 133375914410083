.pages {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
  max-width: 100%;
}

.page {
  display: none;
  align-items: center;
  flex-direction: column;
  padding: 0 10px;
  gap: 40px;
}

.page.active {
  display: flex;
}

.icon {
  font-size: 48px;
}

h2 {
  margin: 16px 0;
  text-align: center;
}

.navigation {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.ellipses {
  display: flex;
  justify-content: center;
}

.ellipse {
  width: 8px;
  height: 8px;
  margin: 0 4px;
  border-radius: 50%;
  background-color: #E4E4E7;
  cursor: pointer;
}

.ellipse.active {
  background-color: #746BF8;
}

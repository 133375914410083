
.ant-popover {
}

.ant-popover-inner-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px !important;
}

.ant-popover-message-title {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.ant-popover-buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.ant-popover-buttons button {
  padding: 12px 16px;
  height: auto;
  flex: 1;
}